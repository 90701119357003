.reportHeadingMain {
    margin-left: 13px;
    width: 95%;
    
}
.reportHeading {
    margin-top: 34px;
    width: 18%;
    display: flex;
    justify-content: space-between;
}
.reportHeading h1 {
    font-family: 'Poppins Bold';
    font-size: 28px;
    margin-left: 10px;
    margin-right: 76px;
} 
.reportAppointUrl {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    display: flex;
   margin-top: 45px;
}
.reportAppointUrl p {
    color: #bdbdbd;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 700;
}
.reportAppointUrl span {
    color: var(--primaryColor);
}
/* .reportHeadingMain hr {
    background: #bdbdbd;
    height: 2px;
    border: none;
} */
.reportMri {
    margin-left: 30px;
    margin-top: 30px;
    width: 95%;
}
.mridateInput {
    width: 20%;
    display: flex;
    flex-direction: column;
}
.mridateInput input {
    height: 50px;
    border: 1px solid #bdbdbd;
    border-radius: 7px;
    margin-top: 5px;
    padding:7px;
}
.mriPrintIcon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}
.iconPrint {
    display: flex;
    align-items: center;
}

.iconPrint p{
    color: var(--primaryColor);
    font-size: 14px;
    font-weight: 700;
    margin-left: 9px;
    margin-bottom: 0;
}
.reportMriDetailHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: 58px;
    width: 100%;
    background-color: #f5f5fa;
    border-radius: 7px;
    margin-top: 20px;
}
.reportMriDetailHeading h3 {
    font-size: 12px;
    font-weight: 700;
    width: 13%;
    text-align: center;
    
}
 .barDateReport {
    width: 95%;
    display: flex;
    border-bottom: 1px solid #bdbdbd;
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    justify-content: space-between;
    top: 13px;
    color: #bdbdbd;
    right: 26px;
}
.reportMriDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px; */
    /* height: 58px; */
    width: 100%;
    
    border-radius: 7px;
    margin-top: 21px;
}
.reportMriDetails p {
    font-size: 12px;
    font-weight: 400;
    width: 13%;
    text-align: center;

}
.flagAnalysisMain {
    margin-left: 13px;
    margin-top: 20px;
    width: 95%;
}
.reportHeading  > img {
    margin-bottom: 12px;
} 
.flagHeading {
    height: 58px;
    background-color: #f5f5fa;
    width: 100%;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}
.flagHeading h4 {
    width: 7%;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
}
.flagDetails {
    /* height: 58px; */
    margin-top: 9px;
    width: 100%;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

}
.flagDetails p {
    font-size: 12px;
    font-weight: 400;
    width: 7%;
    text-align: center;

}

/* .ant-table-tbody > tr > td {
    color: red;
} */

.redText {
    color: red;
}
@media screen and (max-width:768px) {
    .reportMriDetailHeading h3{
        width: 29%;
    }
    .reportMriDetails p {
        width: 29%;
    }
    .mridateInput {
        width: 40%;
    }
    
}
@media  screen and (max-width:912px) {
   
    .mridateInput {
        width: 60%;
    }
    .reportMriDetailHeading h3{
        width: 33%;
        font-size: 12px;
    }
    .reportMriDetails p {
        width: 33%;

        font-size: 10px;
    }
    .mridateInput label {
        font-size: 12px;
    }
    .barDateReport {
        font-size: 12px     ;
    }
    .iconPrint {
        padding-top: 19px;
    }
    .flagAnalysisMain {
        overflow:auto;
        width: 84%;
    }
    .flagHeading {
        width: 860px;
        
    }
    .flagHeading h4 {
        font-size: 12px;
        width: 10%;

    }
    .flagDetails {
        width: 860px;

    }
    .flagDetails p {
        width: 10%;
        font-size: 10px;
    }

}
@media screen and (max-width:540px) {
    .reportMri {
        width: 85%;
    }
    .addPAt {
        flex-direction: column;
    }
    .AddPateintContainer {
        width: 100%;
    }
    .reportAppointUrl {
        font-size: 12px;
        flex-direction: column;
    }
    .reportAppointUrl button {
        margin-top: 19px;
    }
    .RoutesMain {
        width: 83%;
    }
    .addInput {
        overflow: auto  ;
    }
    .reportAppointUrl {
        padding-left: 5px;
    }
    .addInput >.editForm {
        width: 750px;
    }
    .editDetails h1 {
        margin-top: 40px;
    }
    .formInputs >div {
        width: 98%;
    }
    .radioInputs {
        justify-content: space-between;
        width: 90% !important;
    }
    .radioInputs>div {
        margin-left: 1px;
    }
    .radioInputs>div label {
        font-size: 9px !important;
    }
    .formInputs {
        justify-content: left;
    }
    .forFlexRow {
        flex-direction: row !important;
        
    }
    .iconPrint {
        padding-top: 0;
    }
}