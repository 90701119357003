.appointHeading {
  width: 95%;
  margin-left: 13px;
  margin-top: 20px;
}
.headingApp {
  margin-top: 34px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.headingApp h1 {
  font-size: 26px;
  width: 20%;
  font-family: "Poppins Bold";
}
.appointHeading .headingApp button {
  font-size: 12px;
  font-weight: 700;
  box-shadow: 0px 20px 40px rgba(74, 71, 163, 0.2);
  width: 216px;
  height: 50px;
  color: white;
  background-color: var(--primaryColor);
  border: none;
  border-radius: 7px;
}
.appointUrl {
  display: flex;
  justify-content: space-between;
  margin-top: 51px;
  font-size: 14px;
  font-weight: 700;
}
.appointUrl p {
  color: #bdbdbd;
}
.appointUrl p span {
  color: var(--primaryColor);
}
.appointUrl p span:hover {
  cursor: pointer;
}
.appointHeading button:hover {
  cursor: pointer;
}
.appointSpecialty {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.appointSpecialty select {
  -webkit-appearance: none;
  background-image: url("../../../public/images/downArrowBlue.svg") !important;

  background-repeat: no-repeat;
  background-position: right 15px top 18px;
  background-color: transparent;
  margin-top: 7px;
  width: 100% !important;
  height: 50px;
  border: 1px solid #bdbdbd;
  border-radius: 7px;
  padding-left: 20px;
  /* background: transparent; */
}
.appointSpecialty select:focus {
  outline: none;
}
.appointHeading hr {
  margin-top: 20px;
  height: 3px;
  background-color: #f5f5fa;
  border: none;
  border-radius: 30px;
}
.filterAppointment {
  margin-top: 20px;
  width: 97%;
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
}
.filterInputs {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.filtersBtns {
  width: 27%;
}
.dateEnd {
  width: 14%;
}
.dateEnd input {
  width: 100%;
  height: 35px !important;
  border-radius: 7px;
  border: 1px solid #bdbdbd;
  padding: 7px;
}
.dateA {
  width: 14%;
}
.dateA input {
  width: 100%;
  height: 35px !important;
  border-radius: 7px;
  border: 1px solid #bdbdbd;
  padding: 7px;
}
.filtersIconWidht {
  height: 33px !important;
  top: 1px !important;
  right: 1px !important;
  width: 30px !important;
}
.showPickr {
  position: relative;
  width: 100%;
}
.arrowBack {
  display: flex;
}
.arrowBack img {
  margin-right: 15px;
}
.showPickr input::-webkit-calendar-picker-indicator {
  display: none;
}
.showPickr input {
  width: 100%;
  border-radius: 7px;
}
.iconForPicker {
  height: 100%;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  width: 60px;
  background-color: #f5f5fa;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}
.iconForStatus {
  height: 90%;

  width: 60px;
  background-color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  right: 60px;
  top: 8%;
}

.dateRange {
  width: 40%;
}
.dateRange input {
  width: 100%;
  height: 35px;
  border-radius: 7px;
  /* border: 1px solid #bdbdbd; */
  padding: 7px;
}
.caseType {
  width: 26%;
}
.antdSelect > div {
  height: 50px !important;
  border-radius: 7px !important;
}
.antdSelect {
  margin-top: 5px;
}

.caseType select {
  -webkit-appearance: none;
  background-image: url("../../../public/images/downArrowBlue.svg") !important;

  background-repeat: no-repeat;
  background-position: right 15px top 11px;
  background-color: transparent;
  width: 100%;
  height: 35px;
  border-radius: 7px;
  border: 1px solid #bdbdbd;
  padding: 7px;
}
.btnResetFilter {
  background-color: transparent;
  border: none;
  color: red;
  margin-left: 8px;
  margin-top: 17px;
}
.filterAppointment .filtersBtns .centerBtnFilter button {
  background-color: transparent;
  height: 100%;
  border: none;
  margin-right: 7px;
  padding: 7px;
}
.filtersBtns {
  display: flex;
  align-items: center;
  justify-content: center;
}
.centerBtnFilter {
  height: 35px;
  border: 1px solid #bdbdbd;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  margin-top: 20px;
}
.activeBtn {
  background: var(--primaryColor) !important;
  color: white !important; 
}
.btnAll {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  width: 50px;
  border-right: 1px solid #bdbdbd;
}
.btnUpcoming {
  border-right: 1px solid #bdbdbd !important;
  color: #709fb0;
}
.patientSelect {
 display: flex;
 flex-direction: column;
 justify-content: center;
 width: 31%;
}

.btnCompleted {
  color: #709fb0;
}
.allAppointsMain {
  width: 95%;

  margin-left: 13px;
  border-radius: 7px;
  margin-top: 30px;
}
.allAppointHeadings {
  padding-left: 30px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: #f5f5fa;
  height: 58px;
  justify-content: space-around;
}
.allAppointHeadings h5 {
  width: 12%;
  font-size: 12px;
  font-weight: 700;
}
.appointAction {
  width: 4%;
}
.extraWidth {
  width: 172px !important;
}
.appointDetails {
  margin-top: 20px;

  padding-left: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-around;
}
.appointDetails p {
  width: 12%;
  font-size: 12px;
  font-weight: 400;
}
.actionIcons {
  width: 12%;
}
.appointDetails .extraWidth input[type="checkbox"] {
  cursor: pointer;
  /* float: left; */
  /* width: 10% !important; */
  height: 18px;
  width: 18px;
  border-radius: 4px;
}

.actionIcons img {
  cursor: pointer;
}

.appointmentDetailModal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100000;
  display: flex;
  align-items: center;

  justify-content: center;
}
.appointModalDetails {
  width: 79%;
  border-radius: 7px;
  height: 600px;
  position: relative;
  background-color: white;
  overflow: auto;
}
.appointCrossbtn {
  position: absolute;
  top: 16px;
  right: 15px;
  background-color: transparent;
  border: none;
  color: #709fb0;
  font-size: 19px;
  cursor: pointer;
}
.appointModalDetails h1 {
  text-align: center;
  font-family: "Poppins";
  font-size: 36px;
  font-weight: 600;

  margin-top: 50px;
}
.modalAppointDetail {
  margin-top: 20px;
  width: 90%;
  margin-left: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalAppointDetail > div {
  width: 17%;
}
.modalAppointDetail > div p {
  margin-top: 5px;
  font-size: 12px;
}
.modalAppointDetail > div h5 {
  font-weight: 700;
}
.totalAppointDetails {
  width: 95%;
  margin-left: 50px;
}
.pateintDetails {
  margin-top: 6px;
  width: 100% !important;
  position: relative;
  display: flex;
  overflow-x: hidden;
  flex-wrap: wrap;
  align-items: center;

  flex-direction: row;
  /* height: 30px;  */
  position: relative;
}
.pateintDetail {
  flex: 1 0 auto;
  white-space: nowrap;
  margin-top: 10px;
  width: 85% !important;
  position: relative;
  display: flex;
  overflow-x: hidden;
  /* flex-wrap: wrap; */
  align-items: center;

  flex-direction: row;
}
.showPatientsDetail {
  width: 100%;
}
.pateintDetails h4 {
  color: #709fb0;
  position: relative;
  /* width: 100%; */
  display: flex;
  cursor: pointer;
}
/* .appointBorder {
    position: absolute;
    width: 80%;
    right: 110px;
    top: 11px;
    border-top: 2px solid black;
} */
.borderLine {
  width: 900px;
  height: 3px;
  overflow-x: hidden;


  /* border-top: 2px solid #4a47a3; */
  margin-top: -2px;
  margin-left: 16px;
}
.borderOverflow {
  /* width: 80%; */
}
.borderOverflow hr {
  background-color: #709fb0;
  height: 1px;
  border: none;

  width: 900px;
}
.arrowDownSign {
  /* position: absolute;
  left: ; */
  position: absolute;
  top: -3px;
  right: -23px;
  margin-left: 10px;
  font-size: 19px;
  margin-right: 9px;
  margin-bottom: 2px;

  transform: rotate(90deg);
}
.arrowDownSignActive {
  transform: rotate(270deg);
}
.backBtnAppoint {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.backBtnAppoint button {
  box-shadow: 0px 20px 40px rgba(74, 71, 163, 0.2);
  width: 216px;
  height: 50px;
  background: var(--primaryColor);
  border: none;
  border-radius: 7px;
  color: white;
}
.modalAppointPatientDetail {
  margin-top: 20px;
  width: 95%;

  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalAppointPatientDetail > div {
  width: 17%;
}
.modalAppointPatientDetail > div p {
  margin-top: 5px;
  font-size: 12px;
}
.barwithDate {
  margin-left: 30px;
  width: 95%;
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
}
.barDate {
  width: 95%;
  border-bottom: 1px solid #bdbdbd;
  position: absolute;
  top: 13px;
  right: 26px;
}
.barDate h3 {
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 700;
  color: #bdbdbd;
}
.modalAppointPatientDetail2 {
  margin-top: 20px;
  width: 57%;

  flex-wrap: wrap;
  display: flex;

  justify-content: space-between;
}
.modalAppointPatientDetail2 > div {
  width: 31%;
}
.modalAppointPatientDetail2 > div p {
  margin-top: 5px;
  font-size: 12px;
}
.headingApp a {
  text-decoration: none;
  color: white;
}
.createAppointSpecialty {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  width: 100%;
}
.createAppointSpecialty select {
  -webkit-appearance: none;
  background-image: url("../../../public/images/downArrowBlue.svg") !important;

  background-repeat: no-repeat;
  background-position: right 15px top 18px;

  width: 49%;
  height: 50px;
  border: 1px solid #bdbdbd;
  border-radius: 7px;
  padding-left: 15px !important;
  padding: 7px;

  background-color: transparent;
}
.createAppointSpecialty select:focus {
  outline: none;
}
.appointHeading .createAppointSpecialty + hr {
  margin-top: 36px;
}

.createAppointNameinputs {
  display: flex;
  margin-left: 30px;
  width: 95%;
  margin-top: 20px;
  justify-content: space-between;
}
.extraWidth input {
  width: 30%;
}
.fname {
  width: 49%;
  display: flex;
  flex-direction: column;
}
.rangePickerFilter {
  height: 50px;
  border-radius: 7px;
}
.fname input {
  padding-left: 15px !important;

  height: 50px;
  padding: 7px;
  border-radius: 7px;
  margin-top: 7px;
  border: 1px solid #bdbdbd;
}
.dateNameInputs {
  width: 14%;
  display: flex;
  flex-direction: column;
}
.dateNameInputs input {
  width: 100%;
  margin-top: 7px;
  border: 1px solid #bdbdbd;
  padding: 7px;
  height: 50px;
  border-radius: 7px;
}
.createAppointNameinputs input:focus {
  outline: none;
}
.filtersForm {
  width: 80%;
}
/* .modalAppointmdAppointment {
  margin-top: 20px;
  width: 78%;

  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.modalAppointmdAppointment > div {
  width: 17%;
} */
.nameplusBorder {
  width: 90%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.nameplusBorder h3 {
  margin-left: 40px;
  color: #709fb0;
  font-size: 12px;
  font-weight: 700;
}
.nameplusBorder hr {
  border: none;
  height: 3px;
  background-color: #f5f5f4;
  width: 10%;
  top: 9px;
  left: -67px;
  position: absolute;
}
.nameplusBorderExtra {
  width: 90%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.patientSelect select:focus {
  border: 1px solid var(--primaryColor);

}
.nameplusBorderExtra h3 {
  font-size: 12px;
  font-weight: 700;
  margin-left: 40px;

  color: #709fb0;
}
.nameplusBorderExtra hr {
  border: none;
  height: 3px;
  background-color: #f5f5f4;
  width: 10%;
  top: 9px;
  left: -67px;
  position: absolute;
}
@media screen and (max-width: 912px) {
  .fname {
    width: 40%;
  }
  .radioInputs {
    font-size: 13px;
  }
  .allAppointHeadings h5 {
    font-size: 12px;
  }
  .appointDetails p {
    font-size: 12px;
  }
  .filterAppointment .filtersBtns .centerBtnFilter button {
    font-size: 9px;
  }
  .dateNameInputs {
    width: 18%;
  }
  .firstTypeRow a {
    font-size: 12px;
    font-weight: 1000;
  }
  .secondTypeRow a {
    font-size: 12px;
    font-weight: 1000;
  }
  .neuroCheckBoxRef {
    width: 53%;
    left: 424px;
    right: 0;
  }
  .MdCheckBox {
    width: 35%;
  }
  .inputsmriCheckBox label {
    font-size: 10px;
  }
  .neuroCheckBox {
    right: 64px !important;
  }
}
@media screen and (max-width: 778px) {
  .firstTypeRow a {
    font-size: 10px;
  }
  .AppointTypeMain {
    /* width: 800px !important; */
    overflow: auto ;
  }
  .firstTypeRow {
    width: 800px !important;

  }
  .secondTypeRow {
    width: 800px !important;

  }
  .appointCrossbtn {
    right: -190px;
  }
  .allAppointsMain {
    overflow: auto;
  }
  .allAppointHeadings {
    width: 800px;
  }
  .allAppointHeadings h5 {
    width: 130px;
    font-size: 12px;
  }
  .barDate {
    width: 748px;
    left: 0;
    right: 0;
  }
  .appointDetails {
    width: 800px;
  }
  .appointDetails p {
    width: 130px;
    font-size: 12px;
  }
  .headingApp {
    flex-wrap: wrap;
    height: auto;
  }
  .headingApp h1 {
    width: 70%;
  }
  .appointUrl {
    /* margin-top: 70px; */
  }
  .headingApp a {
    margin-top: 14px;
    margin-left: 14px;
  }

  .secondTypeRow a {
    font-size: 10px;
  }
  .neuroCheckBoxRef {
    left: 360px;
    width: 61% !important;
  }
  .editPatientradioInputs input[type="checkbox"] {
    margin-top: 0;
  }
  .editPatientradioInputs {
    font-size: 10px;
  }
  .neuroLableRef p {
    width: 45%;
  }
  .neuroLableRef label {
    width: 45% !important;
  }
  .MdCheckBox {
    width: 42%;
  }
  .neuroLable p {
    width: 45%;
  }
  .neuroLable label {
    width: 45%;
  }
  .filterAppointment {
    flex-wrap: wrap;
  }
  .filtersForm {
    width: 100%;
  }
  .filterInputs {
    flex-wrap: wrap;
    justify-content: left;
  }
  .dateEnd {
    width: 73%;
  }
  .dateA {
    width: 73%;
  }
  .dateRange {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 10px;
  }
  .dateRange > label {
    margin-bottom: 8px;
  }
  .caseType {
    width: 73%;
  }
  .btnResetFilter {
    width: 27%;
  }
  .filtersBtns {
    width: 100%;
  }
  .appointSpecialty select {
    width: 73%;
  }

  .filterAppointment .filtersBtns .centerBtnFilter button {
    font-size: 12px;
  }
  /* .modalAppointDetail {
    width: 750px;
   }
   .borderLine {
    width: 67%;
   } */
  .modalAppointDetail {
    width: 750px;
  }
  .moreDetails {
    width: 785px;
  }
  .totalAppointDetails {
    width: 100%;
  }
  .backBtnAppoint {
    width: 750px;
  }
  .appointModalDetails h1 {
    width: 750px;
  }
}

@media screen and (max-width: 540px) {
  .patientSelect {
    width: 90%;
  }
  .createAppointNameinputs {
    flex-wrap: wrap;
  }
  .appointCrossbtn {
    right: -470px !important;
  }
  .neuroLable p {
    width: 60% !important;
  }
  .crossDetails {
    right: -314px;
  }
  .detailsPatient + button {
    margin-left: 100px;
  }
  .appointCrossbtn {
    right: -450px;
  }
  .nextNeuroRef {
    width: 76% !important;
  }
  .fname {
    width: 80%;
  }
  .dateNameInputs {
    width: 70%;
  }
  .appointUrl {
    font-size: 10px;
  }
  .AppointTypeMain {
    overflow-x: auto;
    width: 89%;
  }
  .firstTypeRow {
    width: 800px !important;
  }
  .firstTypeRow a {
    width: 110px;
  }
  .secondTypeRow {
    width: 700px !important;
  }
  .secondTypeRow a {
    width: 110px;
  }
  .appointHeading .createAppointSpecialty + hr {
    width: 89%;
  }
  .outsideRef {
    overflow: auto;
  }
  .outRef {
    width: 600px !important;
  }
  .neuroCheckBoxRef {
    left: 346px;
  }
  .makeMdAppt {
    overflow: auto;
  }
  .formMd {
    width: 600px !important;
  }
  .MdCheckBox {
    width: 69% !important;
  }
  .editPatientInput {
    overflow: auto;
  }
  .firstEditPatientRow {
    width: 650px;
  }
  .editPatientradioInputs input[type="checkbox"] {
    margin-left: 1px;
  }
  .neuroInputs {
    overflow: auto;
  }
  .neuroInputs {
    width: 650px !important;
  }
  .mriApptMain {
    overflow: auto;
  }
  .formMri {
    width: 680px;
  }
  .makeNeuroAppt {
    overflow: auto;
  }
}
