@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  width: 30px;
  height: 30px;
  border: 2px solid #ffffff;
  border-top: 2px solid #4a47a3;
  border-bottom: 2px solid #4a47a3;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinnerContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
