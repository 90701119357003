.AllPateintMain {
  margin-left: 13px;
  margin-top: 10px;
}
.AllPateintMain h1 {
  font-size: 28px;
  font-family: "Poppins Bold";
}

.new {
  margin-bottom: 5px;
}

.form-group {
  display: block;
}
.popUpClassSelect {
  z-index: 100000 !important;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #bdbdbd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  border-radius: 4px;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked + label:before {
  background-color: var(--primaryColor);
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 7px;
  width: 5px;
  /* background-color: var(--primaryColor); */

  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.patientsUrl p span {
  color: var(--primaryColor);
}
.printPlusIcon h5 {
  color: var(--primaryColor);
  font-size: 14px;
  cursor: pointer;
}
.patientsUrl {
  margin-top: 30px;
  width: 99%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.patientsUrl p {
  width: 15%;
  color: #bdbdbd;
}

.printPlusIcon-wrapper {
  display: flex;
  justify-content: end;
  padding-right: 25px;
  padding-bottom: 20px;
}

.printPlusIcon-wrapper-attorney {
  display: flex;
  padding-right: 25px;
  padding-left: 15px;
  padding-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}

.drange {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 300px;
}

.printPlusIcon {
  /* width: 200px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.filtersWrapper {
  display: flex;
  align-items: center;
}

.filters {
  display: flex;
  gap: 20px;
  padding-inline: 20px;
}

.filters label {
  display: flex;
  cursor: pointer;
}

.filters label span {
  padding-left: 10px;
}

.patientSearch {
  margin-top: 26px;
  width: 100%;
  height: 50px;
  position: relative;
}
.patientSearch input {
  width: 98%;
  height: 50px;
  border: 1px solid #bdbdbd;
  border-radius: 7px;
  padding-left: 45px !important;
}
.patientSearch input:focus {
  outline: none;
}
.patientSearch img {
  position: absolute;
  top: 15px;
  left: 10px;
}
.mstRcent {
  float: right;
  margin-right: 28px;
  margin-top: 20px;
}
.mstRcent button {
  background: none !important;
  color: var(--primaryColor);
  border: none;
  font-family: "Poppins Bold";
  margin-right: 5px;
}
.recentPatients {
  margin-top: 70px;
  width: 100%;
}
.mstRcent:hover {
  cursor: pointer;
}
.recentPatientHeading {
  height: 58px;
  width: 98%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  background-color: #f5f5f4;
  padding-left: 10px;
  border-radius: 7px;
}
.recentPatientHeading h5 {
  font-size: 12px;
  font-weight: 700;
  width: 10%;
  font-family: "Poppins Bold";
}
.recentPatientDetails {
  /* height: 58px; */
  width: 98%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;

  padding-left: 10px;
  border-radius: 7px;
}
.recentPatientDetails h5 {
  width: 10%;
  font-size: 10px;
  font-weight: 350;
}
.actionsRecent {
  width: 10%;
  display: flex;
}
.actionsRecent img {
  margin-left: 12px;
}
.actionsRecent img:hover {
  cursor: pointer;
}
.patientDetailCard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100000;
  display: flex;
  align-items: center;

  justify-content: center;
}
.detailsWithExp h4 {
  font-size: 12px;
  font-weight: 700;
}
.detailsWithExp p {
  font-size: 12px;
  font-weight: 400;
}
.detailCard {
  border-radius: 7px;
  width: 80%;
  height: 600px;
  position: relative;
  background-color: white;
}
.crossDetails {
  background-color: transparent;
  color: #bdbdbd;
  border: none;

  position: absolute;
  top: 19px;
  right: 34px;
  font-size: 30px;
  font-weight: 600;
}
.crossDetails:hover {
  cursor: pointer;
}
.detailCard h1 {
  font-family: "Poppins";
  font-weight: 600;
  text-align: center;
  margin-top: 36px;
}
.detailsContainer {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.detailsContainer > div {
  width: 15%;
}
.detailsPatient {
  width: 100%;
}
.detailsWithExp p {
  margin-top: 7px;
}
.cardBackBtn {
  background: var(--primaryColor);
  width: 216px;
  height: 50px;
  box-shadow: 0px 20px 40px rgba(74, 71, 163, 0.2);
  border: none;
  color: white;
  font-weight: 700;
  border-radius: 7px;
  /* margin-left: 424px; */
  margin-top: 50px;
}
.centerBtnBack {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardBackBtn:hover {
  cursor: pointer;
}
.editPatientDetail {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100000;
  display: flex;
  align-items: center;

  justify-content: center;
}
.addInput {
  width: 100%;
  height: auto;
  position: relative;
  background-color: white;
}
.inputs select {
  -webkit-appearance: none;
  background-image: url("../../../public/images/downArrowBlue.svg") !important;
  background-repeat: no-repeat;
  background-position: right 15px top 18px;
  background-color: transparent;
  margin-top: 7px;
  width: 100%;
  height: 50px;
  border: 1px solid #bdbdbd !important;
  border-radius: 7px;

  padding-left: 20px;
}
.inputs select option:first-child {
  opacity: 0.3;
}
.addInput h1 {
  margin-top: 20px;
  margin-left: 30px;
}
.editDetails {
  border-radius: 7px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 87%;
  height: 94vh;
  position: relative;
  background-color: white;
}
.editDetails h1 {
  font-family: "Poppins Bold";
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  margin-top: 14px;
}
.editForm {
  width: 100%;
  margin-top: 0px;
}
.formInputs {
  display: flex;
  justify-content: space-evenly;
  /* margin-left: 30px; */
  width: 100%;
  margin-top: 8px;
}
.formInputs > div {
  width: 45%;
}
.inputs {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 75px;
}
.inputs input {
  width: 100%;
  height: 50px;
  margin-top: 2px;
  padding-left: 20px;
  border-radius: 7px;
  border: 1px solid #bdbdbd;
  font-size: 18px;
}
.inputs input:focus {
  outline: none;
}
.radioInputs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  /* margin-top: 10px; */
}
.radioInputs > div {
  display: flex;

  align-items: center;
  width: 32%;
}
.radioInputs > div > label {
  margin-right: 7px;
}
.addjustingHeight {
  height: 50px !important;
  top: 1px !important;
  width: 40px !important;
}
.radioInputs input[type="checkbox"] {
  margin-left: 10px;
  height: 18px !important;
  position: absolute;
  margin-top: 2px;

  width: 18px;
  border-radius: 8px;
  padding-left: 10px;
}
.forMarginLeft {
  justify-content: flex-start;
  margin-left: 40px;
}
.updateBtns {
  height: 60px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.updateDetails {
  width: 216px;
  height: 50px;
  background: var(--primaryColor);
  border-radius: 7px;
  border: none;
  color: white;
  box-shadow: 0px 20px 40px rgba(74, 71, 163, 0.2);
}
.cancelDetails {
  width: 216px;
  height: 50px;
  background: none;
  border: none;
  color: red;
}
.updateBtns button:hover {
  cursor: pointer;
}
.patientTopHeading {
  width: 90%;
  margin-left: 30px;
}
.AddPateintContainer {
  width: 93%;
  /* margin-left: 30px; */
  margin-top: 10px;
}
.patientTopHeading h1 {
  margin-top: 34px;
  font-size: 28px;
  font-family: "Poppins Bold";
}
.addpatientsUrl p span {
  color: var(--primaryColor);
}
.printPlusIcon h5 {
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  padding-left: 5px;
}
.addpatientsUrl {
  font-size: 14px;
  font-weight: 700;
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.patientsUrl p {
  width: 21%;
  font-size: 14px;
  font-weight: 700;
  color: #bdbdbd;
}
.addformInputs {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  margin-left: 30px;
  width: 100%;
}
.addformInputs > div {
  width: 47% !important;
}
.addinputs {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 75px;
}

.specialist-select {
  background-repeat: no-repeat;
  background-position: right 15px top 18px;
  background-color: transparent;
  height: 58px;
  width: 100%;
  height: 50px;
  padding: 7px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
}
.addformInputs input {
  width: 100%;
  height: 50px !important;
  margin-top: 2px;
  padding-left: 20px;
  border-radius: 7px;
  border: 1px solid #bdbdbd;
  font-size: 18px;
}
.addinputs input:focus {
  outline: none;
}
.AddSave {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addSave {
  background: var(--primaryColor);
  box-shadow: 0px 20px 40px rgba(74, 71, 163, 0.2);
  width: 216px;
  height: 50px;
  border: none;
  border-radius: 7px;
  color: white;
  margin-top: 30px;
}
.addPatHeight {
  height: 48px !important;
  top: 3px !important;
  right: 1px !important;
}
.addpatientsUrl p {
  color: #bdbdbd;
}
.addPAt {
  margin-top: 34px;

  width: 100%;
  display: flex;
  justify-content: space-between;
}
.addPAt button {
  background: var(--primaryColor);
  width: 216px;
  height: 50px;
  box-shadow: 0px 20px 40px rgba(74, 71, 163, 0.2);
  border: none;
  color: white;
  font-weight: 700;
  border-radius: 7px;
}
.selectOVW {
  -webkit-appearance: none;
  background-image: url("../../../public/images/downArrowBlue.svg") !important;

  background-repeat: no-repeat;
  background-position: right 15px top 18px;
  background-color: transparent;
  height: 50px;
  border: 1px solid #bdbdbd;
  border-radius: 7px;

  padding-left: 20px;
  /* color: rgba(0, 0, 0, 0.3); */
}
.selectOVW option:first-of-type {
  color: red;
}

.selectOVW:focus {
  outline: none;
  color: rgba(0, 0, 0, 1);
}
.selectMainContainer {
  width: 100%;
}
.selectMainContainer .ant-select-single {
  width: 100% !important;
  height: 50px !important;
}
.selectMainContainer .ant-select-single .ant-select-selector {
  height: 100% !important;
  border-radius: 10px !important;
  padding-left: 15px !important;
}
@media screen and (max-width: 1000px) {
  .recentPatientHeading h5 {
    width: 12%;
    font-size: 12px;
  }
  .editPatientDetail {
    height: 100vh;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: auto;
    width: 95%;
  }
  .patientDetailCard {
    height: 100vh;
    width: 100%;

    padding-top: 30px;
    padding-bottom: 30px;
    overflow: auto;
  }
  .recentPatientDetails h5 {
    width: 12%;
    font-size: 10px;
  }
  .actionsRecent {
    width: 12%;
  }
  .formInputs {
    flex-wrap: wrap;
    width: 92%;
    margin-left: 20px;
  }
  .editDetails {
    overflow-y: auto;
  }
  .formInputs > div {
    width: 80%;
  }
  .forMarginLeft {
    justify-content: center;
    /* margin-left: 30px; */
  }
  .detailsContainer > div {
    font-size: 10px;
  }
}
@media screen and (max-width: 768px) {
  .topsearchBar {
    width: 93%;
  }
  .editDetails .crossDetails {
    right: 10px;
  }

  .detailCard {
    overflow: auto;
  }
  .detailsPatient {
    width: 650px;
  }
  .detailsPatient + button {
    margin-left: 0px;
  }
  .detailCard h1 {
    width: 650px;
  }
  .crossDetails {
    right: -6px;
  }

  .recentPatients {
    width: 100%;
    overflow: auto;
  }

  .recentPatientHeading h5 {
    font-size: 12px;
    width: 96px;
    padding-left: 2px;
  }

  .recentPatientDetails h5 {
    font-size: 10px;
    width: 96px;
  }
  .actionsRecent {
    width: 96px;
  }
  .patientsUrl {
    width: 94%;
  }
  .patientsUrl p {
    width: 50%;
    font-size: 10px;
  }
  .printPlusIcon {
    width: 34%;
  }
  .cancelDetails {
    width: 180px;
  }
  .updateDetails {
    width: 180px;
  }
  .updateBtns {
    width: 92%;
  }
}
@media screen and (max-width: 700px) {
  .recentPatientHeading {
    width: 600px;
  }
  .recentPatientDetails {
    width: 600px;
  }
}
