.AppointTypeMain {
  display: flex;
  height: 132px;
  width: 95%;
  margin-left: 13px;
  margin-top: 30px;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 7px;
  padding: 8px;
}
.firstTypeRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.firstTypeRow h3 {
  width: 100%;
  color: #828282;

  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.firstTypeRow a {
  text-decoration: none;
  color: #828282;
  width: 17%;

  height: 35px;
  display: flex;
  align-items: center;
  
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}


.activeType {
  padding: 2px;
  background: var(--primaryColor);
  color: white !important;
  border-radius: 7px;
  font-weight: 700 !important;
 
}
.secondTypeRow a {
  text-decoration: none;
  color: #828282;
  width: 24%;

  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}


.secondTypeRow {
  display: flex;
  justify-content: space-between;
  width: 75%;
  padding-left: 40px;
  margin-top: 20px;
}
.secondTypeRow h3 {
  color: #828282;
  width: 14%;
  cursor: pointer;

  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
}
.makeMdAppt {
  width: 95%;
  margin-top: 39px;
  margin-left: 30px;
}
.nextMdAppt {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.nextMdAppt > div {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.attendedNmissed {
  width: 20%;
}
.nextMdAppt > div input {
  
  height: 50px;
  border: 1px solid #bdbdbd;
  border-radius: 7px;
  padding: 7px;
  margin-top: 7px;
  color: #333333;
}
.nextMdAppt > div label {
  color: #333333;
}
.attendNmiss {
  height: 90%;
    width: 60px;
    background-color: transparent;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    right: 65px;
    top: 10%;
  
}
.attendDate {
  display: flex;
  padding-right: 20px;
  align-items: center;
  justify-content: center;

}
.attendDate label {
  cursor: pointer;
  width: 40px;
  text-align: center;
}
.attendDate > input {
  width: 15px;
  height: 15px !important;
  margin-top: 0 !important;
  margin-right: 5px;
  cursor: pointer;
}
.MdCheckBox {
  display: flex;
  margin-top: 42px;

  width: 22%;
  justify-content: space-between;
}
.MdCheckBox > div {
  display: flex;
  align-items: center;
}
.MdCheckBox > div > label {
  margin-right: 7px;
}
.MdCheckBox > div input {
  width: 18px;
  height: 18px;
  border-radius: 7px;
  margin-left: 10px;
}
.MdDetailInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.firstRowInputMd {
  width: 100%;
  margin-top: 31px;
  justify-content: space-between;
  display: flex;
}
.firstRowInputMd > div {
  display: flex;
  flex-direction: column;
  width: 30%;
}
.firstRowInputMd > div label {
  margin-bottom: 5px;
}
.firstRowInputMd > div input {
  height: 50px;
  border-radius: 7px;
  border: 1px solid #bdbdbd;
  padding: 7px;
  margin-top: 4px;
}
.formMd {
  width: 100%;
}
.mdBtnSave {
  margin-top: 30px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mdBtnSave button {
  width: 216px;
  box-shadow: 0px 20px 40px rgba(74, 71, 163, 0.2);
  height: 50px;
  background: var(--primaryColor);
  border: none;
  border-radius: 7px;
  color: white;
  font-weight: 700;
  cursor: pointer;
}
.editTypePatient {
  width: 95%;
  margin-left: 30px;
  margin-top: 30px;
}
.editPatientInput {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.firstEditPatientRow {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.firstEditPatientRow > div {
  margin-top: 20px;
  width: 47%;
  display: flex;
  flex-direction: column;
}
.firstEditPatientRow > div input {
  height: 50px;
  border: 1px solid #bdbdbd;
  padding: 7px;
  margin-top: 10px;
  border-radius: 7px;
}
.firstEditPatientRow > div input:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.firstEditPatientRow > div select {
  -webkit-appearance: none;
  background-image: url('../../../../public/images/downArrowBlue.svg');
  background-repeat: no-repeat;
  background-position: right 13px top 18px  ;
  height: 50px;
  background-color: transparent;
  border: 1px solid #bdbdbd;
  padding: 7px;
  margin-top: 10px;
  border-radius: 7px;
}
.firstEditPatientRow > div select:focus {
  outline: none;
}
.editPatientradioInputs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 75px;
  margin-top: 10px;
}
.editPatientradioInputs > div {
  margin-top: 19px;
}
.editPatientradioInputs input[type="checkbox"] {
  margin-left: 10px;
  height: 18px;
  position: absolute;
  margin-top: 3px;

  width: 18px;
  border-radius: 8px;
  padding-left: 10px;
}
.makeNeuroAppt {
    width: 95%;
    margin-left: 30px;
    /* margin-top: 30px; */
}
.neuroInputs {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.neuroFirstRow {
    margin-top: 31px;
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: row;
}
.nextNeuro {
    width: 70%;
    
   
    

}
.imgLink {
  margin-top: 9px;
}
.nextNeuro input {
    width: 95% !important;
    height: 50px;
   
    border: 1px solid #bdbdbd;
    padding: 7px;
    margin-top: 10px;
    border-radius: 7px;
}
.neuroWidhtDatePicker {
  width: 90% !important;
}
.neuroLable {
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.neuroLable label {
    width: 25%;
}
.imgeCircleNeuro {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
    width: 30%;
    color: var(--primaryColor);

}
.imgeCircleNeuro img{
  margin-right: 5px;
}

.imgeCircleNeuro p{
  margin: 0;
}

.imgeCircle {
  cursor: pointer;
  display: flex;
  align-items: center;
  
  font-size: 12px;
    width: 26%;
    color: var(--primaryColor);
}
.imgeCircle p {
  margin-bottom: 0;
}
.imgeCircle img {
  margin-right: 5px;
}
.neuroCheckBox {
    width: 20%;
    position: absolute;
    bottom: 15px;
    right: 150px;
    display: flex;
    align-items: center;
}
.neuroCheckBox input {
    width: 18px;
    height: 18px;
    margin-left:6px ;
   
}
.labelnInput {
    width: 45%;
    display: flex;
    
    flex-direction:column ;
   
}
.addnextNeuro {
    width: 60%;
    display: flex;
    justify-content: space-between;
    
    
   
    

} 
.addneuroCheckBox input {
    width: 18px;
    height: 18px;
    margin-left: 5px;
}
.addneuroCheckBox {
    width:20% ;
    margin-left: 20px;
    padding-top: 20px;
   display: flex;
   align-items: center;
   
}
.neuroCheckBox > label {
  margin-right: 7px;

}
.addneuroCheckBox > label {
  margin-right: 7px;
}

.addnextNeuro input {
    margin-top: 5px;
    height: 50px;
    padding: 7px;
    border: 1px solid #bdbdbd;
    border-radius: 7px;
}
.neuroSingleInput {
    width: 70%;
    margin-top: 20px;


}
.neuroSingleInput > div {
    width: 31%;
    display: flex;
    flex-direction: column;
}
.neuroSingleInput > div input {
    height: 50px;
    border:1px solid #bdbdbd;
    border-radius: 7px;
    padding: 7px;
    margin-top: 5px;
}
.clearLastNeuro {
    margin-top: 7px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
}
.clearLastNeuro h2 {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
  padding-top: 5px;
    color: var(--primaryColor);
}
.outsideRef {
    width: 95%;
    margin-left: 30px;
} 
.outRef {
    width: 100%;
}
.neuroCheckBoxRef {
    width: 35%;
    position: absolute;
    bottom: 15px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.neuroCheckBoxRef input {
    width: 18px;
    height: 18px;
    margin-left:6px ;
   
}
.neuroCheckBoxRef > div {
    display: flex;
    align-items: center;
   
    
}
.neuroCheckBoxRef > div label {
  margin-right: 7px;
}
.nextNeuroRef {
    width: 70%;
    display: flex;
    flex-direction: column;
}
.nextNeuroRef input {
    width: 100%;
    height: 50px;
   
    border: 1px solid #bdbdbd;
    padding: 7px;
    margin-top: 10px;
    border-radius: 7px;
}
.neuroLableRef {
    
}
.neuroLableRef {
    width: 85%;
    display: flex;
    justify-content: space-between;
}
.neuroLableRef label {
    width: 25%;
}
/* .neuroLableRef p {
    width: 25%;
    color: var(--primaryColor);
} */
.mriApptMain {
  margin-top: 30px;
  margin-left: 30px;
  width: 95%;
}
.formmri {
  width: 100%;

}
.mriCheckBox {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.mriCheckBox input {
  width: 18px;
  height: 18px;
  margin-left: 20px;

}
.mriCheckBox > label {
  margin-right: 7px;
}
.firstMriRow {
  width: 100%;
}
.firstMriRow h3 {
  font-size: 12px;
  font-weight: 700;

}
.firstRowInput {
  margin-top: 15px;
  width: 100%;
}
.inputsMriFirstRow label {
  margin-bottom: 4px;
}
.inputsMriFirstRow {
  margin-top: 31px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.inputsmri {
  width: 30%; 
   display:flex;
   flex-direction: column;
}
.inputsmri input {
  height: 50px;
  border: 1px solid #bdbdbd;
  border-radius: 7px;
  margin-top: 5px;
  padding: 7px;
}
.inputsmri select {
  -webkit-appearance: none;
  background-image: url('../../../../public/images/downArrowBlue.svg');
  background-repeat: no-repeat;
  background-position: right 13px top 18px  ;
  height: 50px;
  margin-top: 5px;

  border: 1px solid #bdbdbd;
  border-radius: 7px;
  padding: 7px;
  background-color: transparent;

}
.widthOrthoDatePicker {
  width: 84% !important;
}
.inputsmriCheckBox {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: 30px;
  
}
.iconHeightformsNeuro {
  width: 60px !important;
  height: 48px !important;
  top: 11px !important;
  right: 30px !important;

}

.iconHeightforms {
  width: 60px !important;
  height: 48px !important;
  top: 8px !important;
  right: 1px !important;

}
.iconHeightformsappt {
  width: 60px !important;
  height: 48px !important;
  top: 8px !important;
  right: 1px !important; 

}
.iconHeightformseditDetails {
  width: 60px !important;
  height: 48px !important;
  top: 3px !important;
  right: 1px !important; 
}
.iconHeightforms1 {
  width: 60px !important;
  height: 48px !important;
  top: 5px !important;
  right: 1px !important; 
}
.iconHeightformsmri {
  width: 60px !important;
  height: 48px !important;
  top: 6px !important;
  right: 1px !important; 

}
.iconHeightformsedit {
  width: 60px !important;
  height: 48px !important;
  top: 11px !important;
  right: 1px !important; 
  
}

.iconHeightformsOrhto {
  width: 60px !important;
  height: 48px !important;
  top: 11px !important;
  right: 1px !important; 

}
.inputsmriCheckBox input {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}
.inputsmriCheckBox label {
  margin-left: 10px;
}
.barwithDateMRI {
  width: 100%;
  border-bottom: 2px solid #f5f5fa;
  margin-top: 33px;
  margin-bottom: 20px;
} 
.inputsmriExtra {
  width: 22%; 
   display:flex;
   flex-direction: column;

}
.inputsmriExtra input  {
  height: 50px;
  border: 1px solid #bdbdbd;
  border-radius: 7px;
  margin-top: 5px;
  padding: 7px;

}
.inputsmriExtra select {
  -webkit-appearance: none;
  background-image: url('../../../../public/images/downArrowBlue.svg');
  background-repeat: no-repeat;
  background-position: right 11px top 18px  ;
  height: 50px;
  margin-top: 5px;

  border: 1px solid #bdbdbd;
  border-radius: 7px;
  padding: 7px;
  background-color: transparent;


}


.label-with-icon {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.label-with-icon label {
  margin: 0 !important;
  padding: 0;
}

.label-with-icon .edit-icon {
  padding-right: 10px;
  padding-left: 10px;
}

.label-with-icon .edit-icon img {
  width: 12px;
  height: 12px;
  cursor: pointer;
}