.loginPage {
    width: 100%;
    position: relative;
    height: 100vh;
    display: flex;

}
.loginWelcome {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
   
    background-color: var(--primaryColor);
    
}
.loginFirstWelcome {
    width: 48%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 55%;
    
    
}
.loginFirstWelcome h1{
    font-size: 34px;
    margin-top: 14px;
    text-align: center;
    color: white;
    font-family: 'Poppins' !important;
}
.loginFirstWelcome img {
    margin-right: 21px;
}
.loginForm {
    width: 50%;
    border-color: red;
    display: flex;

   
   

}
.loginFormInputs h2 {
    text-align: center;
    font-size: 34px;
    font-family: 'Poppins Bold';
   
}
.loginFormInputs {
    margin-left: 154px;
    margin-top: 40px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
    position: relative;
    
} 

.loginEmail 

{
    margin-top: 20px;
    width: 100%;
}
.loginEmail input {
    margin-top: 5px;
    width: 100%;
    height: 50px;
    padding: 9px;
    border: 1px solid #BDBDBD;
    border-radius: 5px;

}
.loginPassword {
    margin-top: 20px;
    width: 100%;

}
.loginPassword input {
    margin-top: 5px;

    width: 100%;
    height: 50px;
    padding: 9px;
    border: 1px solid #BDBDBD;
    border-radius: 5px;
}
.loginFormInputs button {
    box-shadow: 0px 20px 40px rgba(74, 71, 163, 0.2);
    
    margin-left: 35px;
    margin-top: 120px;
    
    
    width: 216px;
    height: 50px;
    background-color: var(--primaryColor);
    color: white;
    border-radius: 5px;
    border: none;
}
.loginFormInputs * input:focus {
    outline: none;

}
@media screen and (max-width:1400px) {
    .loginFormInputs button {
        left: 70px;
    }
    
}
@media  screen and (max-width:1024px) {
    .loginFormInputs button {
        left: 50px;
    }
    
}
@media screen and (max-width:920px) {
    .loginWelcome {
        display: none;    
    }
    .loginPage {
        /* align-items: center; */
        justify-content: center;
    }
    .loginForm {
        
        width: 100%;
    }
    .loginFormInputs {
        margin-top: 90px;
    }
    .logLogin {
        display: flex !important;
    }
    .loginFormInputs h2 {
        font-size: 28px;
    }
    .loginFormInputs button {
        left: 115px;
    }

    
}
@media  screen and (max-width:560px) {
    .loginFormInputs button {
        /* left: 55px !important;
        bottom: 216px; */
        position: revert;
        margin-top: 80px;
        margin-right: 45px;
            }
    .loginEmail {
        margin-bottom: 10px;
    }
    .loginFormInputs h2 {
        font-size: 20px;
    }
    .loginFormInputs {
        margin-top: 130px !important;
    }
    .loginFormInputs {
        margin-left: 52px !important;
    }
    .loginFormInputs {
        width: 80%;
    } 

    
}
@media screen and (max-width:420px) {
    .loginFormInputs button {
        left: 35px !important;
    }
    .loginForm {
        width: 100%;
    }
    .loginFormInputs {
        margin-left: 70px;
    }
    .loginFormInputs h2 {
        font-size: 19px;
    }
   
    
}
.logLogin {
    position: absolute;
    width: 100%;
    margin-top: 20px;
    display: none;
    align-items: center;
    justify-content: center;
    
}