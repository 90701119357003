.attorneyOfficeForm {
  overflow-y: auto;
  overflow-x: none;

  margin-top: 10px;
  padding: 18px;
  position: relative;
  height: 630px;
  border-radius: 7px;
  width: 85%;
  background-color: white;
}
.crossAttr {
  background-color: transparent;
  color: #bdbdbd;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 19px;
  right: 24px;
  font-size: 27px;
  font-weight: 600;
}
.attorneyOfficeForm h2 {
  text-align: center;
  font-size: 28px;
}
.firstRowInputsOfAttorny {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.firstRowInputsOfAttorny > div {
  margin-top: 20px;
  width: 49%;
  display: flex;
  flex-direction: column;
}
.firstRowInputsOfAttorny > div input {
  color: #333333;
  height: 50px;
  border: 1px solid #b4b4b4;
  border-radius: 7px;
  padding: 7px;
}
.specialtUtlity {
  margin-left: 30px;
  margin-top: 30px;
  width: 95%;
}
.addInputMain {
  width: 100%;
}
.addInputSpecilaty {
  height: 50px;
  position: relative;
  width: 100%;
}
.addInputSpecilaty input {
  width: 100%;
  height: 50px;
  padding: 7px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
}
.addInputSpecilaty button {
  position: absolute;
  box-shadow: 0px 12px 25px rgba(74, 71, 163, 0.2);
  right: 10px;
  top: 10px;
  background-color: var(--primaryColor);
  border: none;
  border-radius: 5px;
  color: white;
  font-family: 800;
  width: 100px;
  height: 30px;
}
.inputValueAdded {
  margin-top: 30px;
  width: 47%;
}
.inputValueAdded h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.reportAppointUrl input {
  background-image: url("../../../public/images/srch.svg");
  background-repeat: no-repeat;
  background-position: top 14px left 5px;
  border: 1px solid #bdbdbd;
  padding-left: 29px !important;
  width: 300px;
  height: 50px;
  border-radius: 7px;
  margin-top: 9px;
}
.addedValueHead {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5fa;
  border-radius: 7px;
}
.addedValueHead h4 {
  font-size: 12px;
  font-weight: 700;
  width: 30%;
  text-align: center;
}
.addedValueDetail {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 7px;
}
.firstRowInputsOfAttorny > div label {
  margin-bottom: 6px;
}
.addInputMain h5 {
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 9px;
}

.addedValueDetail p {
  font-size: 12px;
  font-weight: 400;
  width: 30%;
  text-align: center;
}
.editNdlt {
  width: 30%;
  display: flex;
  justify-content: center;
}
.editNdlt img {
  margin-left: 10px;
  cursor: pointer;
}
.actionimg {
  cursor: pointer;
}
.addInputMainSpecialist {
  width: 100%;
}
.addInputSpecilist {
  width: 47%;
}
.addInputSpecilist input {
  height: 58px;
  width: 100%;
  height: 50px;
  padding: 7px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
}
.addInputSpecilist > div {
  margin-top: 20px;
}
.btnAddNsave {
  width: 100%;
  display: flex;
  justify-content: right;
}
.btnAddNsave button {
  width: 216px;
  box-shadow: 0px 12px 25px rgba(74, 71, 163, 0.2);
  margin-top: 12px;
  font-size: 12px;
  border: none;
  background-color: var(--primaryColor);
  height: 50px;
  color: white;
  font-weight: 800;
  border-radius: 7px;
}
.addInputSpecilist select {
  -webkit-appearance: none;
  background-image: url("../../../public/images/downArrowBlue.svg") !important;
  background-repeat: no-repeat;
  background-position: right 15px top 18px;
  background-color: transparent;
  height: 58px;
  width: 100%;
  height: 50px;
  padding: 7px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
}
.specialtySpacing {
  margin-top: 6px;
}
.ModalAttorney {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnClass {
  font-size: 12px;
  font-weight: 700;
  box-shadow: 0px 20px 40px rgb(74 71 163 / 20%);
  width: 216px;
  height: 50px;
  color: white;
  background-color: var(--primaryColor);
  border: none;
  border-radius: 7px;
}
option:not(:first-of-type) {
  opacity: 0.7;
}
.AttorneyOfficeList {
  width: 96%;
  margin-left: 13px;
  display: flex;
  flex-direction: column;
}

.AttorneyDetails {
  display: flex;
  padding: 10px;
  width: 100%;

  margin-top: 30px;

  border-radius: 7px;
}
.AttorneyDetails > h3 {
  width: 50%;
  font-size: 12px;
  font-weight: 400;
}
.AttorneyHeading {
  display: flex;
  padding: 10px;
  width: 100%;
  background-color: #f5f5fa;
  margin-top: 30px;

  border-radius: 7px;
}
.AttorneyHeading > h3 {
  width: 50%;
  font-size: 12px;
  font-weight: 600;
}

.addInputSpecilist h5 {
  font-size: 12px;
  font-weight: 400;

  margin-bottom: 7px;
}
@media screen and (max-width: 768px) {
  .attorneyOfficeForm {
    width: 88%;
  }
  .firstRowInputsOfAttorny > div {
    width: 100%;
  }
  .firstRowInputsOfAttorny {
    width: 95%;
    flex-wrap: wrap;
  }
  .reportHeadingMain {
    width: 88%;
  }
  .addInputSpecilaty {
    width: 70%;
  }
  .inputValueAdded {
    width: 70%;
  }
  .addInputSpecilist {
    width: 70%;
  }
}
@media screen and (max-width: 420px) {
  .addInputSpecilaty {
    width: 90%;
  }
  .inputValueAdded {
    width: 90%;
  }
  .specialtUtlity {
    width: 91%;
  }
  .addInputSpecilist {
    width: 91%;
  }
}
